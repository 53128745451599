<template>
    <template v-if="appStore.hasStart">
        <div class="pb-10">
            <div class="relative">
                <template v-if="appStore.hasStart === 'special'">
                    <AppCardSpecial :special-id="appStore.start.specialId" class="pb-1" />
                </template>

                <template v-if="appStore.hasStart === 'doc'">
                    <AppCardDocOutline :doc-id="appStore.start.docId" class="pb-1" />
                </template>

                <template v-if="appStore.hasStart === 'service'">
                    <AppCardService :service-id="appStore.start.serviceId" class="pb-1" />
                </template>

                <div class="absolute inset-0 w-full h-full z-10"></div>
            </div>

            <div @click="appStore.unsetStart()" class="text-center underline cursor-pointer text-sm">
                Изменить
            </div>
        </div>
    </template>


    <template v-if="onlyMess">
        <UISocialButtons type="popup" :link="link" />
    </template>
    <template v-else>
        <AppUiHeader title="Сколько лет пациенту?" />
        <AppSelectAge :age-from="ageFrom" :age-to="ageTo" />
    </template>

</template>
<script>

import { useNav } from "~/store/app/nav";
import { useApp } from "~/store/app/app";
import { usePatient } from "~/store/app/patient";
import { useDocs } from "~/store/docs";
import { useStat } from "~/store/stat";

export default {
    setup() {
        const navStore = useNav();
        const appStore = useApp();
        const patientStore = usePatient();
        const docStore = useDocs();

        return {
            navStore,
            appStore,
            patientStore,
            docStore
        };
    },
    computed: {
        ageFrom() {
            if (!this.appStore.start.docId) return 0;

            const doc = this.docStore.getDocById(this.appStore.start.docId);
            return doc.attributes.age_from;
        },
        ageTo() {
            if (!this.appStore.start.docId) return 999;

            const doc = this.docStore.getDocById(this.appStore.start.docId);
            return doc.attributes.age_to;
        },
        onlyMess() {
            if (this.appStore.hasStart === "doc") {
                const doc = this.docStore.getDocById(this.appStore.start.docId);
                if (doc.attributes.onlyMess) {
                    return true;
                }
            }

            return false;
        },
        link() {
            if (this.appStore.hasStart === "doc") {
                const doc = this.docStore.getDocById(this.appStore.start.docId);
                return doc.attributes.messLink.length ? doc.attributes.messLink : "https://wa.me/+79161006469";
            }

            return "https://wa.me/+79161006469";
        }
    },
    mounted() {
        const statStore = useStat();
        statStore.goal("start");
    }
};
</script>
